import logo from "./../assets/images/logo.svg";

interface Props {}

const Header: React.FC<Props> = () => {
  return (
    <header className="header">
      <div className="header__logo">
        <img src={logo} alt="Mlinar logo" />
      </div>
      <div>
        <button className="btn btn--base btn--secondary">
          <a className="type--color--black" href="https://shop.mlinar.hr/">
            Webshop
          </a>
        </button>
      </div>
    </header>
  );
};

export default Header;
