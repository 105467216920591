import Header from "../components/Header";
import card1 from "../assets/images/card-1.jpg";
import card2 from "../assets/images/card-2.jpg";
import card4 from "../assets/images/card-4.jpg";
import pekarskiProizvodi from "../assets/images/pekarski-proizvodi.jpg";
import torte from "../assets/images/torte.jpg";
import fi1 from "../assets/icons/fi1.png";
import fi2 from "../assets/icons/fi2.png";
import fi3 from "../assets/icons/fi3.png";
import fi4 from "../assets/icons/fi4.png";
import fi5 from "../assets/icons/fi5.png";
import fi6 from "../assets/icons/fi6.png";
import fi7 from "../assets/icons/fi7.png";
import fi8 from "../assets/icons/fi8.png";
import promoLjubavnaPrica from "../assets/MLN_pravila Najljepsa ljubavna prica.pdf";
import promoSamciGenijalci from "../assets/MLN_pravila Samci genijalci.pdf";

interface Props { }

const HomePage: React.FC<Props> = () => {
  return (
    <>
      <section className="section--primary mb-24">
        <Header />
        <div className="section--primary__title">
          <h1 className="type--xl">Najljepša pekarska priča</h1>
          <p className="type--md">Tradicija duga već 115 godina</p>
        </div>
        <div className="section--primary__footer">
          <div>
            <i className="icon icon--base icon--scroll-arrows animation--scroll"></i>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/MlinarHrvatska"
            >
              <i className="icon icon--md icon--social-facebook mr-2"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mlinar_1903/?hl=en"
            >
              <i className="icon icon--md icon--social-instagram mr-2"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mlinar/?originalSubdomain=hr"
            >
              <i className="icon icon--md icon--social-linkedin mr-2"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UC1liaN-fpU9XmZwIyTCILyQ"
            >
              <i className="icon icon--md icon--social-youtube"></i>
            </a>
          </div>
        </div>
      </section>
      <section className="container">
        <h3 className="title--section mb-12">Novosti iz Mlinara</h3>
        <div className="row mb-16 mb-sm-45">
          <div className="col col-12 col-sm-6 col-md-4">
            <img
              className="w--100 mb-4"
              src={card2}
              alt="mlinar najprodavaniji proizvod "
            />
            <div className="type--color--secondary mb-4">24.01.2022.</div>
            <p className="type--mds">
              Nova dvotjedna akcija
              <br /> (24.1. - 6.2.)
            </p>
          </div>
          <div className="col col-12 col-sm-6 col-md-4 mb-10 mb-md-0">
            <img className="w--100 mb-4" src={card4} alt="mlinar torta" />
            <div className="type--color--secondary mb-4">10.01.2022.</div>
            <p className="type--mds">
              Nova dvotjedna akcija <br /> (10.1. - 23.1.)
            </p>
          </div>
          <div className="col col-12 col-sm-6 col-md-4 mb-10 mb-md-0">
            <img className="w--100 mb-4" src={card1} alt="mlinar HR" />
            <div className="type--color--secondary mb-4">7.1.2022.</div>
            <p className="type--mds">Izmjena asortimana torti i kolača</p>
          </div>
        </div>
        <h3 className="title--section mb-12 w--445--max">
          Najukusniji pekarski proizvodi u susjedstvu
        </h3>
        <div className="row mb-16 mb-sm-45">
          <div className="col col-12 col-sm-6 mb-10 mb-sm-0">
            <img
              className="w--100 mb-2"
              src={pekarskiProizvodi}
              alt="mlinar najprodavaniji proizvod "
            />
            <p className="type--mds type--wgt--bold mb-2">PEKARSKI PROIZVODI</p>
            <div className="type--color--secondary">
              Pekarski proizvodi ravno iz peći
            </div>
          </div>
          <div className="col col-12 col-sm-6">
            <img
              className="w--100 mb-2"
              src={torte}
              alt="mlinar najprodavaniji proizvod "
            />
            <p className="type--mds type--wgt--bold mb-2">TORTE</p>
            <div className="type--color--secondary">Najukusnije torte</div>
          </div>
        </div>
      </section>
      <section className="section--secondary">
        <h2 className="type--lg">
          Još od 1903. godine, s više od 2000 djelatnika i preko 200
          prodavaonica diljem svijeta, stvaramo najljepšu pekarsku priču.
        </h2>
      </section>
      <footer className="footer footer--primary container type--sm">
        <div className="row">
          <div className="col col-12 col-md-6 mb-12 mb-md-0">
            <div className="row">
              <div className="col col-12 col-md-6 mb-6 mb-md-0">
                <div className="type--color--secondary mb-3">MLINAR</div>
                <div className="mb-2">MLINAR pekarska industrija d.o.o.</div>
                <address className="mb-2">Radnička cesta 228c</address>
                <address>10000 Zagreb, Hrvatska</address>
              </div>
              <div className="col col-12 col-md-6">
                <div className="type--color--secondary mb-3">KONTAKT</div>
                <a className="mb-2 d--b" href="tel:12382300p385">
                  T: +385 1 23 82 300
                </a>
                <a className="mb-2 d--b" href="tel:12382303p385">
                  F: +385 1 23 82 303
                </a>
                <a className="d--b" href="mailto: info@mlinar.eu.com">
                  Email: info@mlinar.eu.com
                </a>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-6">
            <div className="footer__nav">
              <nav className="nav">
                {/* <div className="nav__item">Proizvodi</div>
                <div className="nav__item">Lokacije</div>
                <div className="nav__item">Karijere</div>
                <div className="nav__item">Naša priča</div>
                <div className="nav__item">Kontakt</div> */}
                <div></div>
              </nav>
              <div className="nav d--block d--md--none">
                <div className="nav__item">Sitemap</div>
                <div className="nav__item">Dokumenti</div>
                <div className="nav__item">Pravila privatnosti</div>
              </div>
              <div className="type--color--secondary d--none d--md--b">
                © MLINAR pekarska industrija d.o.o.{" "}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="separator"></div>
      <footer className="footer footer--secondary container type--sm">
        <div className="row">
          <div className="col col-12 col-md-6 flex--primary">
            <img className="mr-4" src={fi1} alt="footer icon 1" />
            <img className="mr-4" src={fi2} alt="footer icon 1" />
            <img className="mr-4" src={fi3} alt="footer icon 1" />
            <img className="mr-4" src={fi4} alt="footer icon 1" />
            <img className="mr-4" src={fi5} alt="footer icon 1" />
            <img className="mr-4" src={fi6} alt="footer icon 1" />
            <img className="mr-4" src={fi7} alt="footer icon 1" />
            <img src={fi8} alt="footer icon 1" />
          </div>
          <div className="col col-12 col-md-6 d--none d--md--b">
            <div className="nav flex--jc--end">
              <div className="nav__item">
                <a href={promoLjubavnaPrica} rel="noreferrer" target="_blank">
                  Promotivna aktivnost “Najljepša ljubavna priča”
                </a>
              </div>
              <div className="nav__item">
                <a href={promoSamciGenijalci} rel="noreferrer" target="_blank">
                  Promotivna aktivnost "Samci genijalci"
                </a>
              </div>
              <div className="nav__item">
                <a href="https://s3.eu-central-1.amazonaws.com/mlinar.rerootdev.xyz/media/files/mlinar_pravila_privatnosti_2018-05-24.docx">
                  Pravila privatnosti
                </a>
              </div>
            </div>
          </div>
          <div className="type--color--secondary d--b d--md--none w--100 type--center mt-10">
            © MLINAR pekarska industrija d.o.o.{" "}
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
